import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { reviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { PerformanceReviewsIntro } from './PerformanceReviewsIntro'
import { PerformanceReviewScorecard } from './PerformanceReviewScorecard'
import {
  OnboardingChecklistContent,
  OnboardingChecklistRoute,
} from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { Route, Switch } from 'react-router-dom'
import { CultureValuesForm } from './CultureValues/CultureValuesForm'
import { CustomQuestionsForm } from './CustomQuestions/CustomQuestionsForm'
import { Settings } from './Settings'
import { performanceSettings } from '@src/api/performanceSettings'

const onboardingSteps: OnboardingChecklistRoute[] = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewsIntro,
  },
  {
    title: 'Setup scorecard',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewScorecard,
  },
]

export const PerformanceReviews = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.VALUES_FORM}>
        <CultureValuesForm />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.CUSTOM_QUESTIONS_FORM}>
        <CustomQuestionsForm />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.ANY}>
        <Settings api={performanceSettings} loadingState={null} />
      </Route>
      <Route>
        <OnboardingChecklistContent config={reviewsConfig} routes={onboardingSteps} />
      </Route>
    </Switch>
  )
}
