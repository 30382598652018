import React from 'react'
import { Location } from 'history'
import { HubAppTabInterface, HubAppType, HubInterface } from '@src/interfaces/hub'
import { ROUTES } from '@src/constants/routes'
import {
  GlobalSettings,
  PerformanceSettings,
  PermissionTypes,
} from '@src/store/auth/types'
import { TimeManagement } from '@src/pages/People/PeopleSubTabs/TimeOffPage/TimeManagement'
import UsersTable from '@src/pages/Admin/AccessManagement/Users/UsersTable'
import GroupsTable from '@src/pages/Admin/AccessManagement/Groups/GroupsTable'
import PermissionsTable from '@src/pages/Admin/AccessManagement/Permissions/PermissionsTable'
import NotificationTable from '@src/pages/People/PeopleSubTabs/Notifications/NotificationsTable'
import { CommunicationGroupsTable } from '@src/pages/People/PeopleSubTabs/CommunicationGroups/CommunicationGroupsTable'
import { EmployeeEmailTemplates } from '@src/pages/People/PeopleSubTabs/EmployeeEmailTemplates/EmployeeEmailTemplates'
import KPIsTable from '@src/pages/Performance/KPIs/KPIsTable'
import Templates from '@src/pages/Forms/KpiTemplates/Templates'
import Rules from '@src/pages/Forms/KpiTemplates/Rules'
import ReviewCyclesTable from '@src/pages/Performance/Review Cycles/ReviewCyclesTable'
import EmployeeTable from '@src/pages/People/PeopleSubTabs/Employees/EmployeeTable'
import SenioritiesTable from '@src/pages/People/PeopleSubTabs/Seniorities/SenioritiesTable'
import DynamicGroupsTable from '@src/pages/People/PeopleSubTabs/Dynamic Groups/DynamicGroups'
import Scheduler from '@src/pages/People/PeopleSubTabs/Attendance/Scheduler'
import ShiftSummary from '@src/pages/People/PeopleSubTabs/Attendance/ShiftSummary'
import ShiftBreakdown from '@src/pages/People/PeopleSubTabs/Attendance/ShiftBreakdown'
import AttendancePolicies from '@src/pages/People/PeopleSubTabs/Attendance/Policies'
import Benchmarks from '@src/pages/People/PeopleSubTabs/Compensation/Benchmarks'
import { Bonuses } from '@src/pages/People/PeopleSubTabs/Compensation/Bonuses'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import BenefitEnrolment from '@src/pages/People/PeopleSubTabs/Benefits/Enrolment'
import BenefitTemplates from '@src/pages/People/PeopleSubTabs/Benefits/Templates'
import { DriversTable } from '@src/apps/People/Engagement/DriversTable/DriversTable'
import { QuestionsTable } from '@src/apps/People/Engagement/QuestionsTable/QuestionsTable'
import { SurveysTable } from '@src/apps/People/Engagement/SurveysTable/SurveysTable'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import { Interviews } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Interviews'
import TeamsTable from '@src/pages/Organisation/OrganisationSubTabs/Teams/TeamsTable'
import DepartmentsTable from '@src/pages/Organisation/OrganisationSubTabs/Departments/DepartmentsTable'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import { HomeSectionId } from '@src/interfaces/tabPinning'
import SupportToolWhitelist from '@src/pages/Performance/SupportTool/Whitelist'
import SupportToolGradeCalibration from '@src/pages/Performance/SupportTool/GradeCalibration'
import SupportToolEligibleEmployees from '@src/pages/Performance/SupportTool/EligibleEmployees'
import EmploymentPoliciesTable from '@src/apps/General/DataRetention/EmploymentPoliciesTable'
import { DataRetentionSettingsTable as RecruitmentPoliciesTable } from '@src/pages/Settings/Candidates/DataRetentionSettings/DataRetentionSettingsTable'
import SkillsTable from '@src/pages/Performance/Skills/SkillsTable'
import CultureValuesTable from '@src/pages/Performance/CultureValues/CultureValuesTable'
import { ContractsTable } from '@src/pages/People/PeopleSubTabs/Contracts/ContractsTable'
import OnboardingEmployeesTable from '@src/pages/People/PeopleSubTabs/OnboardingEmployees/OnboardingEmployeesTable'
import ProbationEmployeesTable from '@src/pages/People/PeopleSubTabs/ProbationEmployees/ProbationEmployeesTable'
import ProbationTemplatesTable from '@src/pages/People/PeopleSubTabs/ProbationEmployees/ProbationTemplatesTable'
import OffboardingTable from '@src/pages/People/PeopleSubTabs/Offboarding/OffboardingTable'
import ResignationsTable from '@src/pages/People/PeopleSubTabs/Resignations/ResignationsTable'
// import { ContractTypes } from '@src/pages/People/PeopleSubTabs/Contracts/ContractTypes'
import { PayCyclesTable } from '@src/apps/People/Payroll/PayCyclesTable/PayCyclesTable'
import { commonDashboardSubtabs } from '@src/apps/General/Todo/common'
import { RequisitionsTableWidget } from '@src/pages/Recruitment/RecruitmentSubTabs/Requisitions/RequisitionsTable'
import { JobPostingsTableWidget } from '@src/pages/Recruitment/RecruitmentSubTabs/Job Postings/JobPostingsTable'
import LocationsTable from '@src/pages/People/PeopleSubTabs/Locations/LocationsTable'
import EntitiesTable from '@src/pages/People/PeopleSubTabs/Entities/EntitiesTable'
import PromotionNomineesTable from '@src/pages/Performance/PromotionNominees/PromotionNominees'
import FeedbackTable from '@src/pages/Admin/AdminSideBars/Feedback/FeedbackTable'
import { RoadmapsMain } from '@src/pages/Forms/RoadmapsMain/RoadmapsMain'
import {
  EmployeeSettingsInterface,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { ScreeningsTable } from '@src/apps/People/Screening/ScreeningsTable'
import { QueriesTable } from '@src/apps/General/DataAnalytics/QueriesTable'
import { KeyPersonsResponsibilitiesTable } from '@src/apps/People/KeyPersons/ResponsibilitiesTable'
import { KeyPersonsResponsibilityAssignmentsTable } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ResponsibilityAssignmentsTable'
import { KeyPersonsSuccessionPlansTable } from '@src/apps/People/KeyPersons/SuccessionPlansTable/SuccessionPlansTable'
import { HelpCenterFaqTable } from '@src/apps/General/HelpCenter/FaqTable/FaqTable'
import { HelpCenterFaqTopicsTable } from '@src/apps/General/HelpCenter/FaqTopicsTable/FaqTopicsTable'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { FaqSDCategoriesTable } from '@src/apps/General/HelpCenter/FaqSDCategoriesTable/FaqSDCategoriesTable'
import { Token } from '@revolut/ui-kit'
import { Dashboards } from '@src/pages/Performance/Analytics/Dashboards/Dashboards'

export type AppKey = keyof typeof appConfig & string

function createApplicationsMap<T extends { [name in AppKey]: HubAppType }>(cfg: T) {
  return cfg
}

export const colors = {
  teal: '#13d1a3',
  blue: '#0A84FF',
  deepPurple: '#805CFF',
}

const appConfig = {
  accessManagement: {
    id: 'accessManagement' as const,
    title: 'Access Management',
    icon: 'LockClosed' as const,
    image: 'access-management',
    bg: Token.color.deepGrey,
    settingsConfig: settingsConfig.accessManagement,
    tabs: [
      {
        homeSectionId: HomeSectionId.accessManagementUsers,
        title: 'Users',
        homeSectionTitle: 'User access',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS,
        canView: [PermissionTypes.ViewUser],
        component: UsersTable,
      },
      {
        homeSectionId: HomeSectionId.accessManagementGroups,
        title: 'Groups',
        homeSectionTitle: 'Access groups',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS,
        canView: [PermissionTypes.ViewGroup],
        component: GroupsTable,
      },
      {
        homeSectionId: HomeSectionId.accessManagementPermissions,
        title: 'Permissions',
        path: ROUTES.ADMIN.ACCESS_MANAGEMENT.PERMISSIONS,
        url: ROUTES.ADMIN.ACCESS_MANAGEMENT.PERMISSIONS,
        canView: [PermissionTypes.ViewPermissions],
        component: PermissionsTable,
      },
    ],
  },
  feedback: {
    id: 'feedback' as const,
    title: 'Feedback',
    image: 'feedback',
    icon: 'SocialLike' as const,
    path: ROUTES.ADMIN.FEEDBACK,
    url: ROUTES.ADMIN.FEEDBACK,
    bg: Token.color.deepGrey,
    globalSetting: GlobalSettings.FeedbackEnabled,
    canView: [PermissionTypes.ViewUserfeedback],
    component: FeedbackTable,
  },
  todo: {
    id: 'todo' as const,
    title: 'To Do',
    image: 'todo',
    icon: '16/ListBullet' as const,
    bg: Token.color.deepGrey,
    tabs: commonDashboardSubtabs,
  },
  dataRetention: {
    id: 'dataRetention' as const,
    title: 'Data Retention',
    image: 'data-retention',
    icon: 'Database' as const,
    path: ROUTES.APPS.DATA_RETENTION.ANY,
    url: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
    bg: Token.color.deepGrey,
    tabs: [
      {
        homeSectionId: HomeSectionId.dataRetentionEmploymentPolicies,
        title: 'Employment policies',
        path: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
        url: ROUTES.APPS.DATA_RETENTION.EMPLOYMENT,
        canView: [PermissionTypes.ViewDataRetentionPeriod],
        component: EmploymentPoliciesTable,
      },
      {
        homeSectionId: HomeSectionId.dataRetentionRecruitmentPolicies,
        title: 'Recruitment policies',
        path: ROUTES.APPS.DATA_RETENTION.RECRUITMENT,
        url: ROUTES.APPS.DATA_RETENTION.RECRUITMENT,
        canView: [PermissionTypes.ViewDataRetentionPeriod],
        component: RecruitmentPoliciesTable,
      },
    ],
  },
  communication: {
    id: 'communication' as const,
    title: 'Communication',
    image: 'notifications',
    icon: 'Bell' as const,
    path: ROUTES.APPS.COMMUNICATION.ANY,
    url: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
    bg: Token.color.deepGrey,
    settingsConfig: settingsConfig.communication,
    tabs: [
      {
        homeSectionId: HomeSectionId.notifications,
        title: 'Notifications',
        path: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
        url: ROUTES.APPS.COMMUNICATION.NOTIFICATIONS,
        canView: [PermissionTypes.ViewTemplatedNotifications],
        component: NotificationTable,
      },
      {
        homeSectionId: HomeSectionId.communicationGroups,
        title: 'Groups',
        homeSectionTitle: 'Communication groups',
        path: ROUTES.APPS.COMMUNICATION.GROUPS,
        url: ROUTES.APPS.COMMUNICATION.GROUPS,
        canView: [PermissionTypes.ViewCommunicationGroup],
        component: CommunicationGroupsTable,
      },
      {
        homeSectionId: HomeSectionId.communicationEmailTemplates,
        title: 'Email templates',
        path: ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES,
        url: ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES,
        canView: [PermissionTypes.ViewEmployeeEmailTemplate],
        component: EmployeeEmailTemplates,
      },
    ],
  },
  kpis: {
    id: 'kpis' as const,
    title: 'Goals',
    icon: 'Target' as const,
    image: 'goals',
    bg: Token.color.indigo,
    settingsConfig: settingsConfig.kpis,
    tabs: [
      {
        homeSectionId: HomeSectionId.kpis,
        title: 'KPIs',
        path: ROUTES.PERFORMANCE.KPIS,
        url: ROUTES.PERFORMANCE.KPIS,
        canView: [PermissionTypes.ViewKpi],
        component: KPIsTable,
      },
      {
        homeSectionId: HomeSectionId.kpisTemplates,
        title: 'Templates',
        homeSectionTitle: 'KPI templates',
        path: ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES,
        url: ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES,
        canView: [PermissionTypes.ViewKPITemplate],
        component: Templates,
      },
      {
        homeSectionId: HomeSectionId.kpisRules,
        title: 'Rules',
        homeSectionTitle: 'KPI rules',
        path: ROUTES.FORMS.KPI_TEMPLATES.RULES,
        url: ROUTES.FORMS.KPI_TEMPLATES.RULES,
        canView: [PermissionTypes.ViewKPITemplate],
        component: Rules,
      },
    ],
  },
  promotionNominees: {
    id: 'promotionNominees' as const,
    title: 'Promotions',
    image: 'promotions',
    path: ROUTES.PERFORMANCE.PROMOTION_NOMINEES,
    url: ROUTES.PERFORMANCE.PROMOTION_NOMINEES,
    icon: 'ArrowUpgrade' as const,
    bg: Token.color.indigo,
    globalSetting: GlobalSettings.PromotionsEnabled,
    canView: [PermissionTypes.ViewPromotionNomination],
    component: PromotionNomineesTable,
  },
  values: {
    id: 'values' as const,
    title: 'Company Values',
    image: 'skills',
    icon: 'Heart' as const,
    path: ROUTES.PERFORMANCE.VALUES,
    url: ROUTES.PERFORMANCE.VALUES,
    canView: [PermissionTypes.ViewCompanyValue],
    bg: Token.color.background,
    component: CultureValuesTable,
  },
  skills: {
    id: 'skills' as const,
    title: 'Skills',
    image: 'skills',
    icon: 'Services' as const,
    bg: Token.color.indigo,
    settingsConfig: settingsConfig.skills,
    tabs: [
      {
        homeSectionId: HomeSectionId.skillsSkills,
        title: 'Skills',
        path: ROUTES.PERFORMANCE.SKILLS,
        url: ROUTES.PERFORMANCE.SKILLS,
        canView: [PermissionTypes.ViewSkillset],
        component: SkillsTable,
      },
    ],
  },
  roadmaps: {
    id: 'roadmaps' as const,
    title: 'Roadmaps',
    image: 'pipelines',
    url: ROUTES.PERFORMANCE.ROADMAPS,
    path: ROUTES.PERFORMANCE.ROADMAPS,
    icon: 'DataLimit' as const,
    bg: Token.color.indigo,
    component: RoadmapsMain,
    settingsConfig: settingsConfig.roadmaps,
  },
  performanceReview: {
    id: 'performanceReview' as const,
    title: 'Reviews',
    icon: 'StarSemi' as const,
    image: 'performance-reviews',
    bg: Token.color.indigo,
    settingsConfig: settingsConfig.performance,
    tabs: [
      {
        homeSectionId: HomeSectionId.performanceReviewCycles,
        title: 'Performance Reviews',
        homeSectionTitle: 'Performance reviews',
        path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ANY,
        url: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
        canView: [PermissionTypes.ViewReviewCycles],
        subtabs: [
          {
            id: 'review-cycles',
            title: 'Review Cycles',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
            component: ReviewCyclesTable,
          },
          {
            id: 'eligible-employees',
            title: 'Eligible employees',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ELIGIBLE_EMPLOYEES,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ELIGIBLE_EMPLOYEES,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolEligibleEmployees,
          },
          {
            id: 'grade-calibration',
            title: 'Grade calibration',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.GRADE_CALIBRATION,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.GRADE_CALIBRATION,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolGradeCalibration,
          },
          {
            id: 'whitelist',
            title: 'Whitelist',
            path: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.WHITELIST,
            to: ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.WHITELIST,
            permission: PermissionTypes.ViewReviewsWhitelist,
            component: SupportToolWhitelist,
          },
        ],
      },
      {
        homeSectionId: HomeSectionId.probation,
        title: 'Probation',
        path: ROUTES.PERFORMANCE.PROBATION.ANY,
        url: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
        performanceSetting: PerformanceSettings.EnableProbation,
        subtabs: [
          {
            id: 'employees',
            homeSectionId: HomeSectionId.probation,
            title: 'Employees',
            path: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
            to: ROUTES.PERFORMANCE.PROBATION.EMPLOYEES,
            component: () => <ProbationEmployeesTable category="probation" />,
          },
          {
            id: 'templates',
            homeSectionId: HomeSectionId.probation,
            title: 'Templates',
            path: ROUTES.PERFORMANCE.PROBATION.TEMPLATES,
            to: ROUTES.PERFORMANCE.PROBATION.TEMPLATES,
            permission: PermissionTypes.ViewProbationEmployees,
            component: ProbationTemplatesTable,
          },
        ],
      },
      {
        homeSectionId: HomeSectionId.pip,
        title: 'PIP',
        path: ROUTES.PERFORMANCE.PIP,
        url: ROUTES.PERFORMANCE.PIP,
        component: () => <ProbationEmployeesTable category="pip" />,
        performanceSetting: PerformanceSettings.EnablePip,
      },
    ],
  },
  employees: {
    id: 'employees' as const,
    title: 'Employees',
    icon: 'Profile' as const,
    image: 'employees',
    bg: Token.color.lightBlue,
    settingsConfig: settingsConfig.employees,
    tabs: [
      {
        homeSectionId: HomeSectionId.employees,
        title: 'Employees',
        path: ROUTES.APPS.EMPLOYEES.ALL,
        url: ROUTES.APPS.EMPLOYEES.ALL,
        canView: [PermissionTypes.ViewEmployees],
        component: EmployeeTable,
      },
    ],
  },
  lifecycle: {
    id: 'lifecycle' as const,
    title: 'Lifecycle',
    image: 'onboarding',
    icon: 'Rocket' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.LIFECYCLE.ANY,
    url: ROUTES.APPS.LIFECYCLE.ONBOARDING,
    settingsConfig: settingsConfig.lifecycle,
    tabs: [
      {
        homeSectionId: HomeSectionId.onboarding,
        title: 'Onboarding',
        path: ROUTES.APPS.LIFECYCLE.ONBOARDING,
        url: ROUTES.APPS.LIFECYCLE.ONBOARDING,
        canView: [PermissionTypes.ViewEmployeeHROnboardingProcess],
        component: OnboardingEmployeesTable,
      },
      {
        homeSectionId: HomeSectionId.offboarding,
        title: 'Offboarding',
        path: ROUTES.APPS.LIFECYCLE.OFFBOARDING,
        url: ROUTES.APPS.LIFECYCLE.OFFBOARDING,
        canView: [PermissionTypes.ViewOffboardingV2],
        component: OffboardingTable,
      },
      {
        homeSectionId: HomeSectionId.resignations,
        title: 'Resignations',
        path: ROUTES.APPS.LIFECYCLE.RESIGNATIONS,
        url: ROUTES.APPS.LIFECYCLE.RESIGNATIONS,
        canView: [PermissionTypes.ViewResignations],
        globalSetting: GlobalSettings.ResignationsEnabled,
        component: ResignationsTable,
      },
    ],
  },
  timeOff: {
    id: 'timeOff' as const,
    title: 'Time Management',
    icon: 'Calendar' as const,
    image: 'time-off',
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.TIME_MANAGEMENT.TIME_MANAGEMENT,
    url: ROUTES.APPS.TIME_MANAGEMENT.TIME_MANAGEMENT,
    settingsConfig: settingsConfig.timeOff,
    component: TimeManagement,
  },
  contracts: {
    id: 'contracts' as const,
    title: 'Contracts',
    image: 'contracts',
    bg: 'yellow',
    path: ROUTES.APPS.CONTRACTS.ANY,
    url: ROUTES.APPS.CONTRACTS.ALL,
    settingsConfig: settingsConfig.contracts,
    tabs: [
      {
        homeSectionId: HomeSectionId.contracts,
        title: 'Contracts',
        path: ROUTES.APPS.CONTRACTS.ALL,
        url: ROUTES.APPS.CONTRACTS.ALL,
        canView: [PermissionTypes.ViewContractsTab],
        component: ContractsTable,
      },
      // TODO: temporarily hidden, uncomment when BE is ready - https://revolut.atlassian.net/browse/RHR-4806
      // {
      //   homeSectionId: HomeSectionId.contractTypes,
      //   title: 'Types',
      //   homeSectionTitle: 'Contract types',
      //   path: ROUTES.APPS.CONTRACTS.TYPES,
      //   url: ROUTES.APPS.CONTRACTS.TYPES,
      //   canView: [PermissionTypes.ViewContractType],
      //   component: ContractTypes,
      // },
    ],
  },
  payroll: {
    id: 'payroll' as const,
    title: 'Payroll',
    image: 'payroll',
    icon: 'Credit' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
    url: ROUTES.APPS.PAYROLL.PAY_CYCLES_TABLE,
    globalSetting: GlobalSettings.PayrollEnabled,
    settingsConfig: settingsConfig.payroll,
    canView: [PermissionTypes.ViewPaygroup],
    component: PayCyclesTable,
  },
  attendance: {
    id: 'attendance' as const,
    title: 'Attendance',
    image: 'attendance',
    icon: 'TimeOutline' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.ATTENDANCE.ANY,
    url: ROUTES.APPS.ATTENDANCE.SCHEDULER,
    settingsConfig: settingsConfig.attendance,
    globalSetting: GlobalSettings.AttendanceEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.attendanceSchedule,
        title: 'Schedule',
        path: ROUTES.APPS.ATTENDANCE.SCHEDULER,
        url: ROUTES.APPS.ATTENDANCE.SCHEDULER,
        canView: [PermissionTypes.ViewDirectReportsSchedule],
        component: Scheduler,
      },
      {
        homeSectionId: HomeSectionId.attendanceShifts,
        title: 'Shifts',
        path: ROUTES.APPS.ATTENDANCE.SHIFT_SUMMARY,
        url: ROUTES.APPS.ATTENDANCE.SHIFT_SUMMARY,
        canView: [PermissionTypes.ViewShiftsSummary],
        component: ShiftSummary,
      },
      {
        homeSectionId: HomeSectionId.attendanceCompensation,
        title: 'Compensation',
        homeSectionTitle: 'Attendance compensation',
        path: ROUTES.APPS.ATTENDANCE.SHIFT_BREAKDOWN,
        url: ROUTES.APPS.ATTENDANCE.SHIFT_BREAKDOWN,
        canView: [PermissionTypes.ViewShiftsBreakdown],
        component: ShiftBreakdown,
      },
      {
        homeSectionId: HomeSectionId.attendancePolicies,
        title: 'Policies',
        homeSectionTitle: 'Attendance policies',
        path: ROUTES.APPS.ATTENDANCE.POLICIES,
        url: ROUTES.APPS.ATTENDANCE.POLICIES,
        canView: [PermissionTypes.ViewSchedulingPolicy],
        component: AttendancePolicies,
      },
    ],
  },
  compensation: {
    id: 'compensation' as const,
    title: 'Compensation',
    image: 'compensation',
    path: ROUTES.APPS.COMPENSATION.ANY,
    url: ROUTES.APPS.COMPENSATION.BANDS,
    icon: 'Cash' as const,
    bg: Token.color.lightBlue,
    globalSetting: GlobalSettings.TotalCompensationEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.compensationBands,
        title: 'Bands',
        path: ROUTES.APPS.COMPENSATION.BANDS,
        url: ROUTES.APPS.COMPENSATION.BANDS,
        canView: [PermissionTypes.ViewBenchmarks],
        component: Benchmarks,
      },
      {
        homeSectionId: HomeSectionId.compensationBonuses,
        title: 'Bonuses',
        path: ROUTES.APPS.COMPENSATION.BONUSES,
        url: ROUTES.APPS.COMPENSATION.BONUSES,
        canView: [PermissionTypes.ViewEmployeeBonusBulkUploadSession],
        component: Bonuses,
      },
    ],
  },
  documents: {
    id: 'documents' as const,
    title: 'Documents',
    image: 'documents',
    icon: 'DocumentsPair' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.DOCUMENTS.DOCUMENTS,
    url: ROUTES.APPS.DOCUMENTS.DOCUMENTS,
    canView: [PermissionTypes.ViewEmployeeDocuments],
    component: AllDocuments,
    settingsConfig: settingsConfig.documents,
  },
  benefits: {
    id: 'benefits' as const,
    title: 'Benefits',
    image: 'benefits',
    icon: 'Present' as const,
    bg: Token.color.lightBlue,
    globalSetting: GlobalSettings.BenefitsEnabled,
    tabs: [
      {
        homeSectionId: HomeSectionId.benefitEnrolment,
        title: 'Enrolment',
        homeSectionTitle: 'Benefit enrolment',
        path: ROUTES.APPS.BENEFITS.ENROLMENT,
        url: ROUTES.APPS.BENEFITS.ENROLMENT,
        canView: [PermissionTypes.ViewBenefitsTemplate],
        component: BenefitEnrolment,
      },
      {
        homeSectionId: HomeSectionId.benefitTemplates,
        title: 'Templates',
        homeSectionTitle: 'Benefit templates',
        path: ROUTES.APPS.BENEFITS.TEMPLATES,
        url: ROUTES.APPS.BENEFITS.TEMPLATES,
        canView: [PermissionTypes.ViewBenefitsTemplate],
        component: BenefitTemplates,
      },
    ],
  },
  engagement: {
    id: 'engagement' as const,
    title: 'Engagement',
    icon: 'Questionnaire' as const,
    image: 'engagement',
    bg: Token.color.indigo,
    path: ROUTES.APPS.ENGAGEMENT.ANY,
    url: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
    globalSetting: GlobalSettings.EngagementEnabled,
    settingsConfig: settingsConfig.engagement,
    tabs: [
      {
        homeSectionId: HomeSectionId.engagementSurveys,
        title: 'Surveys',
        path: ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: SurveysTable,
      },
      {
        homeSectionId: HomeSectionId.engagementDrivers,
        title: 'Drivers',
        path: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: DriversTable,
      },
      {
        homeSectionId: HomeSectionId.engagementQuestions,
        title: 'Questions',
        path: ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE,
        url: ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE,
        canView: [PermissionTypes.ViewEngagement],
        component: QuestionsTable,
      },
    ],
  },
  keyPersons: {
    id: 'keyPersons' as const,
    title: 'Key Persons',
    image: 'succession',
    bg: Token.color.lightBlue,
    icon: 'ArrowRightLeft' as const,
    path: ROUTES.APPS.KEY_PERSONS.ANY,
    url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    settingsConfig: settingsConfig.keyPersons,
    tabs: [
      {
        homeSectionId: HomeSectionId.keyPersonsRiskProfiles,
        title: 'Assignments',
        path: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_ASSIGNMENTS_TABLE,
        component: KeyPersonsResponsibilityAssignmentsTable,
        canView: [PermissionTypes.ViewKeyRiskProfile],
      },
      {
        homeSectionId: HomeSectionId.keyPersonsResponsibilities,
        title: 'Responsibilities',
        path: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE,
        component: KeyPersonsResponsibilitiesTable,
        canView: [PermissionTypes.ViewKeyPersonType],
      },
      {
        homeSectionId: HomeSectionId.keyPersonsSuccessionPlans,
        title: 'Succession',
        path: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLANS_TABLE,
        url: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLANS_TABLE,
        component: KeyPersonsSuccessionPlansTable,
        canView: [PermissionTypes.ViewKeyRiskProfileSuccessionPlans],
      },
    ],
  },
  jobs: {
    id: 'jobs' as const,
    title: 'Jobs',
    icon: 'Megaphone' as const,
    image: 'job-postings',
    bg: Token.color.teal,
    canView: [PermissionTypes.ViewJobdescriptions, PermissionTypes.ViewRequisitions],
    tabs: [
      {
        homeSectionId: HomeSectionId.requisitions,
        title: 'Requisitions',
        path: ROUTES.APPS.JOBS.REQUISITIONS,
        url: ROUTES.APPS.JOBS.REQUISITIONS,
        canView: [PermissionTypes.ViewRequisitions],
        globalSetting: GlobalSettings.RequisitionsEnabled,
        component: RequisitionsTableWidget,
      },
      {
        homeSectionId: HomeSectionId.jobPostings,
        title: 'Job postings',
        path: ROUTES.APPS.JOBS.JOB_POSTINGS,
        url: ROUTES.APPS.JOBS.JOB_POSTINGS,
        canView: [PermissionTypes.ViewJobdescriptions],
        globalSetting: GlobalSettings.JobPostingsEnabled,
        component: JobPostingsTableWidget,
      },
    ],
  },
  candidates: {
    id: 'candidates' as const,
    title: 'Candidates',
    icon: 'AddContact' as const,
    image: 'candidates',
    bg: Token.color.teal,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    path: ROUTES.APPS.CANDIDATES,
    url: ROUTES.APPS.CANDIDATES,
    canView: [PermissionTypes.ViewCandidate],
    component: () => <CommonCandidatesTable type="common" />,
  },
  interviews: {
    id: 'interviews' as const,
    title: 'Interviews',
    icon: 'Chat' as const,
    image: 'hiring-process',
    bg: Token.color.teal,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    path: ROUTES.APPS.INTERVIEWS,
    url: ROUTES.APPS.INTERVIEWS,
    canView: [PermissionTypes.ViewInterviewFeedback],
    component: Interviews,
  },
  teams: {
    id: 'teams' as const,
    title: 'Teams',
    icon: 'People' as const,
    image: 'teams',
    bg: Token.color.deepGrey,
    settingsConfig: settingsConfig.teams,
    tabs: [
      {
        homeSectionId: HomeSectionId.teams,
        title: 'Teams',
        path: ROUTES.APPS.TEAMS.TEAMS,
        url: ROUTES.APPS.TEAMS.TEAMS,
        canView: [PermissionTypes.ViewTeams],
        component: TeamsTable,
      },
      {
        homeSectionId: HomeSectionId.departments,
        title: 'Departments',
        path: ROUTES.APPS.TEAMS.DEPARTMENTS,
        url: ROUTES.APPS.TEAMS.DEPARTMENTS,
        canView: [PermissionTypes.ViewDepartment],
        component: DepartmentsTable,
      },
      {
        homeSectionId: HomeSectionId.company,
        title: 'Company',
        path: ROUTES.FORMS.COMPANY.KPI.GOALS,
        url: ROUTES.FORMS.COMPANY.KPI.GOALS,
        // usually we don't want to push tabs routes to the history, so back button leads to hub apps landing
        // but for company we navigate to the organisation layout with own tabs and want back button to return to teams app
        urlDescriptor: (location: Location<{ history: string[] }>) => {
          return {
            pathname: ROUTES.FORMS.COMPANY.KPI.GOALS,
            state: {
              ...location.state,
              history: location.state?.history
                ? [...location.state.history, location.pathname]
                : [location.pathname],
            },
          }
        },
      },
    ],
  },
  positions: {
    id: 'positions' as const,
    title: 'Roles',
    image: 'positions',
    icon: 'RepairTool' as const,
    bg: Token.color.deepGrey,
    settingsConfig: settingsConfig.roles,
    tabs: [
      {
        homeSectionId: HomeSectionId.specialisations,
        title: 'Specialisations',
        path: ROUTES.APPS.POSITIONS.SPECIALISATIONS,
        url: ROUTES.APPS.POSITIONS.SPECIALISATIONS,
        canView: [PermissionTypes.ViewRoles],
        component: SpecialisationsTable,
      },
      {
        homeSectionId: HomeSectionId.roles,
        title: 'Roles',
        path: ROUTES.APPS.POSITIONS.ROLES,
        url: ROUTES.APPS.POSITIONS.ROLES,
        canView: [PermissionTypes.ViewRoles],
        component: RolesTable,
      },
      {
        homeSectionId: HomeSectionId.functions,
        title: 'Functions',
        path: ROUTES.APPS.POSITIONS.FUNCTIONS,
        url: ROUTES.APPS.POSITIONS.FUNCTIONS,
        canView: [PermissionTypes.ViewFunction],
        component: FunctionsTable,
      },
      {
        homeSectionId: HomeSectionId.employeesSeniorities,
        title: 'Seniorities',
        path: ROUTES.APPS.POSITIONS.SENIORITIES,
        url: ROUTES.APPS.POSITIONS.SENIORITIES,
        canView: [PermissionTypes.ViewSeniority],
        component: SenioritiesTable,
      },
      {
        homeSectionId: HomeSectionId.employeesSkills,
        title: 'Skills',
        path: ROUTES.APPS.POSITIONS.SKILLS,
        url: ROUTES.APPS.POSITIONS.SKILLS,
        canView: [PermissionTypes.ViewSkillset],
        component: SkillsTable,
      },
    ],
  },
  locations: {
    id: 'locations' as const,
    title: 'Locations',
    image: 'locations',
    icon: 'LocationPin' as const,
    path: ROUTES.APPS.LOCATIONS,
    url: ROUTES.APPS.LOCATIONS,
    bg: Token.color.deepGrey,
    canView: [PermissionTypes.ViewLocations],
    component: LocationsTable,
    settingsConfig: settingsConfig.locations,
  },
  entities: {
    id: 'entities' as const,
    title: 'Legal Entities',
    image: 'legal-entities',
    icon: 'Bank' as const,
    path: ROUTES.APPS.ENTITIES,
    url: ROUTES.APPS.ENTITIES,
    bg: Token.color.deepGrey,
    canView: [PermissionTypes.ViewEntity],
    component: EntitiesTable,
    settingsConfig: settingsConfig.legalEntities,
  },
  screening: {
    id: 'screening' as const,
    title: 'Screening',
    image: 'screening',
    icon: 'Search' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.SCREENING.SCREENING_TABLE,
    url: ROUTES.APPS.SCREENING.SCREENING_TABLE,
    globalSetting: GlobalSettings.ScreeningEnabled,
    canView: [PermissionTypes.ViewAllScreeningChecks],
    component: ScreeningsTable,
    settingsConfig: settingsConfig.screening,
  },
  groups: {
    id: 'groups' as const,
    title: 'Groups',
    image: 'groups',
    icon: 'People' as const,
    bg: Token.color.lightBlue,
    path: ROUTES.APPS.GROUPS,
    url: ROUTES.APPS.GROUPS,
    canView: [PermissionTypes.ViewDynamicGroups],
    component: DynamicGroupsTable,
    settingsConfig: settingsConfig.groups,
  },
  careerPageSettings: {
    id: 'careerPageSettings' as const,
    title: 'Careers Page',
    image: 'careers-page',
    bg: Token.color.teal,
    path: ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE,
    url: ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE,
    canView: [PermissionTypes.ViewCareerPagePreferences],
    globalSetting: GlobalSettings.CareerPageEnabled,
    settingsConfig: settingsConfig.careersPage,
  },
  reportingApp: {
    id: 'reportingApp' as const,
    title: 'Analytics',
    icon: 'BarChart' as const,
    image: 'analytics',
    bg: Token.color.indigo,
    tabs: [
      {
        HomeSectionId: HomeSectionId.reportingAppDashboard,
        title: 'Dashboard',
        path: ROUTES.APPS.DATA_ANALYTICS.DASHBOARD,
        url: ROUTES.APPS.DATA_ANALYTICS.DASHBOARD,
        component: Dashboards,
        canView: [PermissionTypes.UseReportingApp],
      },
      {
        homeSectionId: HomeSectionId.reportingAppQueries,
        title: 'Queries',
        path: ROUTES.APPS.DATA_ANALYTICS.QUERIES,
        url: ROUTES.APPS.DATA_ANALYTICS.QUERIES,
        canView: [PermissionTypes.UseReportingApp],
        component: QueriesTable,
      },
    ],
  },
  helpCenter: {
    id: 'helpCenter' as const,
    title: 'Help Centre',
    image: 'help-center',
    icon: 'Question' as const,
    bg: Token.color.deepGrey,
    path: ROUTES.APPS.HELP_CENTER.ANY,
    url: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    settingsConfig: settingsConfig.helpCentre,
    tabs: [
      {
        homeSectionId: HomeSectionId.supportFaq,
        title: 'FAQs',
        path: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
        url: ROUTES.APPS.HELP_CENTER.FAQ_TABLE,
        component: HelpCenterFaqTable,
        canView: [PermissionTypes.ViewFaq],
      },
      {
        homeSectionId: HomeSectionId.supportFaqTopics,
        title: 'FAQ Topics',
        path: ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE,
        url: ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE,
        component: HelpCenterFaqTopicsTable,
        canView: [PermissionTypes.ViewFaqTopic],
      },
      {
        title: 'SD Categories',
        path: ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE,
        url: ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE,
        component: FaqSDCategoriesTable,
        canView: [PermissionTypes.ViewFaq],
      },
    ],
  },
}

export const APPLICATIONS = createApplicationsMap(appConfig)

export const PINNABLE_APPLICATION_TABS = Object.values(APPLICATIONS).reduce(
  (tabs, app) => {
    if ('tabs' in app) {
      return [
        ...tabs,
        ...Object.values(app.tabs)
          .filter(
            tab => 'homeSectionId' in tab && ('component' in tab || 'subtabs' in tab),
          )
          .map(tab => ({ ...tab, category: app.title })),
      ]
    }
    return tabs
  },
  [] as HubAppTabInterface[],
)

export const globalSettingsAppTabsFilter = (
  id: HomeSectionId,
  settings: {
    employeeSettings?: EmployeeSettingsInterface
    performanceSettings?: PerformanceSettingsInterface
  },
) => {
  if (id === HomeSectionId.employeesCustomFields) {
    return !!settings.employeeSettings?.enable_custom_fields
  }

  if (id === HomeSectionId.kpisTemplates || id === HomeSectionId.kpisRules) {
    return !!settings.performanceSettings?.allowed_kpi_types.find(
      type => type.id === 'template',
    )
  }

  return true
}

export const HUB_APPLICATIONS: HubInterface = [
  {
    id: 'people',
    title: 'People',
    items: [
      APPLICATIONS.employees,
      APPLICATIONS.timeOff,
      APPLICATIONS.payroll,
      APPLICATIONS.documents,
    ],
  },
  {
    id: 'recruitment',
    title: 'Recruitment',
    items: [APPLICATIONS.jobs, APPLICATIONS.candidates, APPLICATIONS.interviews],
  },
  {
    id: 'performance',
    title: 'Performance',
    items: [
      APPLICATIONS.kpis,
      APPLICATIONS.performanceReview,
      APPLICATIONS.reportingApp,
      APPLICATIONS.engagement,
    ],
  },
  {
    id: 'organisation',
    title: 'Organisation',
    items: [APPLICATIONS.teams, APPLICATIONS.positions],
  },
]

export const HUB_INTEGRATIONS = [
  {
    id: 'slack',
    title: 'Slack',
    image: 'slack',
    url: ROUTES.FEATURES.INTEGRATION.SLACK.ALL,
    canView: [PermissionTypes.ViewSlackIntegration],
  },
  {
    id: 'jira',
    title: 'Jira',
    image: 'jira',
    url: ROUTES.FEATURES.INTEGRATION.JIRA,
    canView: [PermissionTypes.ViewJiraIntegration],
  },
  {
    id: 'calendar',
    title: 'Google Calendar',
    image: 'calendar',
    url: ROUTES.FEATURES.INTEGRATION.CALENDAR,
    canView: [PermissionTypes.ViewGoogleCalendarIntegration],
  },
  {
    id: 'docusign',
    title: 'DocuSign',
    image: 'docusign',
    url: ROUTES.FEATURES.INTEGRATION.DOCUSIGN,
    canView: [PermissionTypes.ViewDocuSignIntegration],
  },
]
