import { Flex, IconButton, TableWidget, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStatusColumn,
  roadmapStartDate,
  roadmapResolutionAtColumn,
  roadmapOwnerColumn,
  roadmapJiraLabelColumn,
  roadmapEpicUrlColumn,
  getRoadmapActionsColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React, { useState } from 'react'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { captureException } from '@sentry/react'
import { roadmapsRequests } from '@src/api/roadmaps'
import { useTableReturnType } from '@src/components/Table/hooks'
import { useGetRoadmapSettings } from '@src/api/settings'

export const RoadmapsList = ({
  viewMode,
  table,
  onSelected,
}: {
  viewMode: boolean
  table?: useTableReturnType<RoadmapInterface>
  onSelected?: (item: RoadmapInterface) => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const { confirmationDialog, confirm, showError } = useSubmitFlowHelpers({
    confirmProps: { variant: 'compact' },
  })

  const cells: ColumnCellInterface<RoadmapInterface>[] = viewMode
    ? [
        {
          ...roadmapGenericNameColumn,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          width: 80,
        },
        {
          ...roadmapProgressColumn,
          width: 100,
        },
        {
          ...roadmapStatusColumn,
          width: 80,
        },
        {
          ...roadmapStartDate,
          width: 80,
        },
        {
          ...roadmapDueDate,
          width: 80,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 80,
        },
        {
          ...roadmapOwnerColumn,
          width: 80,
        },
        roadmapSettings?.jira_epics_enabled
          ? {
              ...roadmapJiraLabelColumn,
              width: 80,
            }
          : null,
        {
          ...roadmapEpicUrlColumn,
          width: 80,
        },
        {
          ...getRoadmapActionsColumn({
            deleteActionProps: {
              handleDelete: async id => {
                const confirmed = await confirm({
                  label: 'Confirm',
                  body: 'Are you sure you want to delete roadmap?',
                  yesBtnVariant: 'negative',
                  yesMessage: 'Delete',
                })

                if (confirmed.status !== 'confirmed') {
                  return
                }

                try {
                  await roadmapsRequests.deleteItem(id)
                  values.roadmaps = values.roadmaps.filter(r => r.id !== id)
                } catch (err) {
                  captureException(err)
                  showError('Failed to delete roadmap', 'Please, try again')
                }
              },
            },
            onSelected,
          }),
          width: 80,
        },
      ].filter(Boolean)
    : [
        {
          type: CellTypes.text,
          idPoint: 'review_cycle.id',
          dataPoint: 'review_cycle.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Cycle',
          width: 100,
        },
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        roadmapSettings?.jira_epics_enabled
          ? {
              ...roadmapJiraLabelColumn,
              width: 80,
            }
          : null,
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }: { data: RoadmapInterface }) => {
            return (
              <Flex justifyContent="flex-end" alignItems="center" gap="s-12">
                {onSelected && !roadmapSettings?.jira_epics_enabled ? (
                  <IconButton
                    useIcon="Pencil"
                    size={16}
                    onClick={() => onSelected(data)}
                    color={Token.color.greyTone50}
                  />
                ) : null}
                <IconButton
                  disabled={!!pendingDeleteId}
                  onClick={async () => {
                    try {
                      const confirmed = await confirm({
                        body: `Are you sure you want to delete ${data.name} roadmap`,
                        yesBtnVariant: 'negative',
                        yesMessage: 'Delete',
                      })

                      if (confirmed.status === 'confirmed') {
                        setPendingDeleteId(data.id)
                        await roadmapsRequests.deleteItem(data.id)
                        values.roadmaps = values.roadmaps.filter(r => r.id !== data.id)
                      }
                    } catch (err) {
                      captureException(err)
                      showError('Failed to delete roadmap')
                    } finally {
                      setPendingDeleteId(undefined)
                    }
                  }}
                  useIcon={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
                  size={16}
                  color={Token.color.greyTone50}
                />
              </Flex>
            )
          },
          title: '',
          width: 50,
        },
      ].filter(Boolean)

  return values.roadmaps?.length ? (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<RoadmapInterface & { actions_column?: never }>
          hideCountAndButtonSection
          name={TableNames.RoadmapsMain}
          {...(table || {})}
          data={values.roadmaps}
          count={values.roadmaps.length}
          dataType="Roadmap"
          row={{
            cells,
          }}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  ) : null
}
