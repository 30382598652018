import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'

/**
 * Include settingsConfig property in appConfig in src/constants/hub.tsx if there is a respective app for settings.
 * This will automatically add a link to settings page in the app title.
 */
export const settingsConfig = {
  attendance: {
    path: ROUTES.SETTINGS.ATTENDANCE,
    title: 'Attendance',
    canView: [PermissionTypes.ViewAttendanceTrackingPreferences],
  },
  employees: {
    path: ROUTES.SETTINGS.EMPLOYEES.GENERAL,
    title: 'Employees',
    canView: [PermissionTypes.ViewEmployeePreferences],
  },
  lifecycle: {
    path: ROUTES.SETTINGS.LIFECYCLE,
    title: 'Lifecycle',
    canView: [PermissionTypes.ViewLifecycleSettings],
  },
  engagement: {
    path: ROUTES.SETTINGS.ENGAGEMENT.EDIT,
    title: 'Engagement surveys',
    canView: [PermissionTypes.ChangeEngagement],
  },
  timeOff: {
    path: ROUTES.SETTINGS.TIME_OFF.GENERAL,
    title: 'Time management',
    canView: [PermissionTypes.ViewTimeOffPreferences],
  },
  documents: {
    path: ROUTES.SETTINGS.DOCUMENTS,
    title: 'Documents',
    canView: [PermissionTypes.ViewDocumentsPreferences],
  },
  contracts: {
    path: ROUTES.SETTINGS.CONTRACTS,
    title: 'Contracts',
    canView: [PermissionTypes.ViewContractsPreferences],
  },
  screening: {
    path: ROUTES.SETTINGS.SCREENING,
    title: 'Screening',
    canView: [PermissionTypes.ViewScreeningPreferences],
  },
  payroll: {
    path: ROUTES.SETTINGS.PAYROLL.GENERAL,
    title: 'Payroll',
    canView: [PermissionTypes.ViewPayrollPreferences],
  },
  keyPersons: {
    path: ROUTES.SETTINGS.KEY_PERSONS,
    title: 'Key persons',
    canView: [PermissionTypes.ViewKeyPersonPreferences],
  },
  jobs: {
    path: ROUTES.SETTINGS.JOBS.LIST,
    title: 'Jobs',
    canView: [
      PermissionTypes.ViewRequisitionPreferences,
      PermissionTypes.ViewJobPostingPreferences,
    ],
  },
  candidates: {
    path: ROUTES.SETTINGS.CANDIDATES.LIST,
    title: 'Candidates',
    canView: [PermissionTypes.ViewCandidatePreferences],
  },
  careersPage: {
    path: ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE,
    title: 'Careers page',
    canView: [PermissionTypes.ViewCareerPagePreferences],
  },
  teams: {
    path: ROUTES.SETTINGS.ORGANISATION.LIST,
    title: 'Teams',
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
  },
  roles: {
    path: ROUTES.SETTINGS.ROLES.GENERAL,
    title: 'Roles',
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
  },
  legalEntities: {
    path: ROUTES.SETTINGS.ENTITIES,
    title: 'Legal entities',
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
  },
  locations: {
    path: ROUTES.SETTINGS.LOCATIONS,
    title: 'Locations',
    canView: [
      PermissionTypes.ViewLocationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
  },
  kpis: {
    path: ROUTES.SETTINGS.GOALS.ALL,
    title: 'Goals',
    canView: [PermissionTypes.ViewPerformancePreferences],
  },
  performance: {
    path: ROUTES.SETTINGS.PERFORMANCE.REVIEW_CYCLES,
    title: 'Performance',
    canView: [PermissionTypes.ViewPerformancePreferences],
  },
  roadmaps: {
    path: ROUTES.SETTINGS.ROADMAPS,
    title: 'Roadmaps',
    canView: [PermissionTypes.ViewRoadmapPreferences],
  },
  skills: {
    path: ROUTES.SETTINGS.SKILLS,
    title: 'Skills & Values',
    canView: [PermissionTypes.ViewSkillsPreferences],
  },
  communication: {
    path: ROUTES.SETTINGS.COMMUNICATION,
    title: 'Communication',
    canView: [PermissionTypes.ViewCommunicationSettings],
  },
  accessManagement: {
    path: ROUTES.SETTINGS.ACCESS_MANAGEMENT,
    title: 'Access Management',
    canView: [PermissionTypes.ViewAccessManagementPreferences],
  },
  groups: {
    path: ROUTES.SETTINGS.GROUPS,
    title: 'Groups',
    canView: [PermissionTypes.ViewEmployeePreferences],
  },
  helpCentre: {
    path: ROUTES.SETTINGS.HELP_CENTER.GENERAL,
    title: 'Help Centre',
    canView: [PermissionTypes.ViewHelpCenterPreferences],
  },
  customFields: {
    path: ROUTES.SETTINGS.CUSTOM_FIELDS,
    title: 'Custom Fields',
    canView: [PermissionTypes.ViewCustomField],
  },
  reporting: {
    path: ROUTES.SETTINGS.ANALYTICS,
    title: 'Reporting',
    canView: [PermissionTypes.UseReportingApp],
  },
}
