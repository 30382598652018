import React from 'react'

import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { performanceSettings } from '@src/api/performanceSettings'

import { GoalsSettingsProps } from './Goals'
import { Roadmaps } from '@src/pages/Settings/Roadmaps/Roadmaps'

export const GoalsSettingsRoadmaps = ({ roadmapsRoute }: GoalsSettingsProps) => {
  const routes = [
    {
      title: 'General',
      path: roadmapsRoute,
      url: roadmapsRoute,
      canView: [
        PermissionTypes.ViewRoadmapPreferences,
        PermissionTypes.ChangeRoadmapPreferences,
      ],
      component: Roadmaps,
      hideGlobalSearch: true,
    },
  ]

  return <SettingsForm routes={routes} api={performanceSettings} title="Roadmaps" />
}
